.bg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(#222, #0a0a0a);
    overscroll-behavior: contain;
    position: fixed;
    z-index: -1;
}

h3 {
    font-weight: 400;
    color: yellow;
    text-align: center;
    font-size: 24px;
    margin: 0;
}
