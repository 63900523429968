.section {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #0009;
    /* 48 for page border, + 32 for section padding + 2 for border = 82px */
    width: calc(100vw - 82px);
    height: fit-content;
    max-width: 800px;
    color: #fffb;
    padding: 16px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
}

.section-content {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: normal;
}

.section-title {
    font-weight: 600;
    font-size: 32px;
}

.corner-paw {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 8px;
    right: 12px;
    stroke-width: 4px;
    fill: none;
    opacity: 0.2;
}

.title-flourish {
    background-image: linear-gradient(90deg, #ffe76a, #ffbe26, #fff4b7);
    color: transparent;
    background-clip: text;
    background-size: 400% 100%;
    animation: gradient 2s linear infinite;
    animation-direction: alternate;
    user-select: none;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
